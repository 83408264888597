import React, { useState } from 'react';
import styled from 'styled-components';
import { ColInSection, Section } from '../components/layoutComponents';
// import roomData from '../data/roomData.json';
import Button from '../styles/button';
import { Link } from 'gatsby';
import {
  AiOutlineArrowRight,
  AiOutlineCalendar,
  AiOutlinePicture,
} from 'react-icons/ai';
import { useTheme } from 'styled-components/macro';
import { theme } from '../styles/theme';
import ModalComponent from '../components/layoutComponents/modal';
import { useContext } from 'react';
import { Context } from '../components/rootElement';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { wobble } from '../styles/animations';
import ListElWithIcon from '../components/listElWithIcon';
// import { SEO, useSEO } from "gatsby-plugin-seo";

const StSection = styled(Section)`
  &:nth-child(4n) {
    background-color: ${({ theme }) => theme.babyPowder()};
  }
  &:nth-child(4n + 1) {
    background-color: ${({ theme }) => theme.yellowGreenCrayola()};
  }
  &:nth-child(4n + 2) {
    background-color: ${({ theme }) => theme.platinum()};
  }
  &:nth-child(4n + 3) {
    background-color: ${({ theme }) => theme.littleBoyBlue()};
    color: white;
  }
  &:nth-child(1) {
    margin-top: ${({ theme }) => theme.headerHeightBig}px;
  }
`;

const StButton = styled(Button)`
  text-align: center;
  margin: 0px auto;
  &&& {
    color: ${({ theme }) => theme.jet()};
  }
  @media only screen and (min-width: 1024px) {
    max-width: 50%;
  }
`;

const PicStub = styled.div`
  width: 100%;
  height: 350px;
  border: 2px dashed lightcoral;
  border-radius: 5px;
`;
const StColInSection = styled(ColInSection).attrs({
  col: 2,
})`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
`;

const StColInSection2 = styled(ColInSection)`
  font-family: ${({ fontFamily }) => fontFamily || 'sacramento'};
  font-size: 2em;
  text-align: center;
  color: ${({ theme }) => theme.babyPowder()};
  @media only screen and (min-width: 1024px) {
    padding: 0px 100px;
    font-size: 4em;
  }
`;

const AnimColInSection = styled(ColInSection)`
  animation: ${wobble} 1.6s infinite both;
`

const RoomsPage = () => {
  const { greenRYB, headerHeightBig, platinum, fireOpal, davysGrey, yellowGreenCrayola } = useTheme(theme);
  const [unitClicked, setUnitClicked] = useState(false);
  const [modalContent, setModalContent] = useState(false);
  const { unitsArr } = useContext(Context);

  const unitClickHandler = (room) => {
    setUnitClicked(true);
    setModalContent(room);
  };

  const roomData = unitsArr.filter(
    (node) => node.unitType === 'Guesthouse Room'
  );

  return (
    <>
      {/* <SEO
        title="Guest House Rooms - Touch of Class Guest House"
        description="These short term units are ideal for business trips and weekend retreats."
      /> */}
      <Section
        style={{ marginTop: headerHeightBig, textShadow: `0px 0px 10px ${greenRYB()}`}}
        backgroundColor={yellowGreenCrayola()}
      >
        <StColInSection2 col={1}>Guesthouse Rooms</StColInSection2>
      </Section>
      {roomData.map((room, idx) => {
        return (
          <StSection key={`room-${idx}`} alignItems='stretch'>
            <ColInSection col={1}><h3 style={{textAlign: 'center'}}>{room.name}</h3></ColInSection>
            <StColInSection>
              {room.images.map((image, idx) =>
                image.title.includes('cover') ? (
                  <GatsbyImage
                    key={`room-img${idx}`}
                    image={getImage(image.gatsbyImageData)}
                    alt=''
                    style={{boxShadow: '0px 0px 15px -6px #000000', borderRadius: 5}}
                  />
                ) : null
              )}
              {/* <PicStub /> */}
              <p style={{ marginTop: 40 }}>{room.description.description}</p>
              <StButton
                type='button'
                onClick={() => unitClickHandler(room)}
                style={{ marginTop: 16 }}
              >
                <AiOutlinePicture /> View Images
              </StButton>
            </StColInSection>
            <StColInSection>
              <h4
                style={{
                  textAlign: 'center',
                  padding: 10,
                  // backgroundColor: 'rgba(255,255,255,0.5)',
                  borderBottom: `2px solid ${
                    (idx + 3) % 4 === 0
                      ? 'rgba(255,255,255,0.5)'
                      : 'rgba(0,0,0,0.5)'
                  }`,
                }}
              >
                Features
              </h4>
              <ul>
              {room.roomFeatures.map((feat, index) => (
                  <ListElWithIcon key={`room-${idx}-feat${index}`} feature={feat}/>
                ))}
              </ul>
              <StButton as={Link} to='https://www.nightsbridge.co.za/bridge/book?bbid=17738'>
                <AiOutlineCalendar /> Book Online
              </StButton>
            </StColInSection>
          </StSection>
        );
      })}
      <Section backgroundColor={platinum()}>
        <AnimColInSection
          col={1}
          style={{ fontSize: '1.5em', textAlign: 'center', textTransform: 'uppercase' }}
          color={fireOpal()}
        >
          <Link to='/self-catering' >
            View our self catering units? <AiOutlineArrowRight />
          </Link>
        </AnimColInSection>
      </Section>
      <ModalComponent
        show={unitClicked}
        setShow={setUnitClicked}
        content={modalContent}
      />
    </>
  );
};

export default RoomsPage;
